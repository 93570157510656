import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/layout';
import Helmet from "react-helmet"
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import HubspotForm from 'react-hubspot-form'

const MasterClass = ({ location }) => {
    const [openSectionId, setOpenSectionId] = useState(null);
    const contentRef = useRef({});
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const toggleSection = (id) => {
        setOpenSectionId(openSectionId === id ? null : id);
    };
    useEffect(() => {
        if (openSectionId !== null) {
            const section = contentRef.current[openSectionId];
            if (section) {
                section.style.maxHeight = section.scrollHeight + 'px';
            }
        } else {
            Object.values(contentRef.current).forEach((section) => {
                section.style.maxHeight = '0px';
            });
        }
    }, [openSectionId]);

    return (
        <Layout location={location}>
            <Helmet>
                <title>Vantage Masterclass Series</title>

                <meta
                    name="description"
                    content="Recognition Revolution: Drive Belonging, Reinforce Culture, Drive Impact"
                />
                <meta property="twitter:title" content="Vantage Masterclass Series" />
                <meta name="robots" content="index" />

                <meta
                    property="twitter:description"
                    content="Recognition Revolution: Drive Belonging, Reinforce Culture, Drive Impact"
                />

                <meta property="og:type" content="website" />

                <meta
                    property="og:url"
                    content="https://www.vantagecircle.com/careers/"
                />

                <meta property="og:site_name" content="Vantage Circle" />
                <meta property="og:title" content="Vantage Masterclass Series" />

                <meta
                    property="og:description"
                    content="Recognition Revolution: Drive Belonging, Reinforce Culture, Drive Impact"
                />
                <link
                    rel="canonical"
                    href="https://www.vantagecircle.com/masterclassUSA-HRCI/"
                ></link>
            </Helmet>
            <section id="home"
                className="mclassHerobanner py-2 lg:py-5 lg:flex justify-around items-center w-11/12 lg:max-w-7xl mx-auto p-5 xl:pr-3 rounded-lg"
            >
                <div className="w-full z-10 relative">
                    <div className="w-full lg:w-9/12 flex flex-col text-center lg:text-left justify-center lg:items-start items-center md:pl-7 md:pt-5">
                        <div className="my-2">
                            <div className="masterclass-label">Vantage Masterclass Series</div>
                            <p className="text-center lg:text-left text-base md:text-2rem">In collaboration with HRCI</p>
                        </div>
                        <h1 className="text-pink lg:pr-3 xl:w-full text-xl md:text-4xl text-center lg:text-left relative mb-3 no-br">
                            Nourishing Strategy with Culture: <br />
                            The Science of How R&R Works
                        </h1>
                        <p className='mb-0 text-base md:text-2xl'>A 3-Part Masterclass Series on Recognition and Rewards</p>
                        <p className="my-8 text-indigo-100 bg-purple-tagColor text-center lg:text-left w-max rounded-lg py-1 px-5 md:text-3xl">
                            September 6th
                        </p>
                        <div className="xl:flex justify-center items-center lg:justify-start w-full md:w-1/2 lg:w-10/12 2xl:w-full cursor-pointer mb-8 z-10">
                            <button onClick={onOpenModal} className="vc-new-orange-btn-rounded-full text-white lato rounded-full mb-3 xl:mb-0 mx-auto xl:ml-0 xl:mr-2">
                                Register for event
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="about" className="w-full py-10 xl:pb-16 xl:pt-20">
                <div className="w-10/12 xl:w-auto xl:max-w-7xl mx-auto">
                    <h2 className="homepage-section-heading text-left">About the Series</h2>
                    <p className="mt-10">
                        The modern workplace demands a fresh perspective on employee engagement and retention. While traditional reward systems may have sufficed in the past, today's high-performance culture requires a more strategic and data-driven approach.
                    </p>
                    <p>
                        This masterclass series is designed to empower HR professionals with the tools and insights to create exceptional employee experiences through thoughtfully designed rewards and recognition programs. By leveraging the latest research, industry best practices, and real-world case studies, we will explore how to quantify the impact of recognition, align reward strategies with business objectives, and foster a culture of appreciation that drives employee satisfaction and productivity.
                    </p>
                    <p>
                        Join us as we delve into innovative strategies, measurement frameworks, and practical implementation steps to transform your organization's reward and recognition initiatives into powerful business drivers.
                    </p>
                </div>
            </section>

            <section className="w-full py-10 xl:pb-16 xl:pt-20 bg-purple-30">
                <div className="w-10/12 xl:w-auto xl:max-w-7xl mx-auto">
                    <h2 className="homepage-section-heading text-left">Key Takeaways</h2>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
                        <div className="rounded-lg p-10 bg-white border-t-8 border-pink">
                            <h2 className="homepage-mini-heading font-bold mb-4">Unlock the science behind R&R</h2>
                            <p className='mb-0'>Leverage behavioral insights & expert guidance to understand why recognition works and how to make it work for your company.</p>
                        </div>
                        <div className="rounded-lg p-10 bg-white border-t-8 border-pink">
                            <h2 className="homepage-mini-heading font-bold mb-4">Craft your R&R strategy</h2>
                            <p className='mb-0'>Explore best-in-class program elements, budgeting, and powerful ROI measurement techniques.</p>
                        </div>
                        <div className="rounded-lg p-10 bg-white border-t-8 border-pink">
                            <h2 className="homepage-mini-heading font-bold mb-4">Become a data-driven R&R leader</h2>
                            <p className='mb-0'>Master key metrics, diagnose program gaps, and take action for continuous improvement.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="masterclass" className="w-full py-10 xl:pb-16 xl:pt-16">
                <div className="w-10/12 xl:w-auto xl:max-w-7xl mx-auto">
                    <div className='homepage-section-heading text-white rounded mb-10 bg-purple-200 w-max px-8 py-4'>Masterclasses</div>
                    {/* First Masterclass */}
                    <div className="rounded-lg bg-purple-30 p-6 md:pr-6 pb-6 mb-12">
                        <div className="md:flex flex-col lg:flex-row items-start gap-9">
                            <div className="w-full lg:w-1/5">
                                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1724674279/gatsbycms/uploads/2024/08/Image-1_2.png" alt="Chessboard and handshake" className="rounded-lg shadow-md w-full h-auto" />
                            </div>
                            <div className="w-full lg:w-4/5 flex flex-col justify-start">
                                <h2 className="pt-6 text-2xl lg:text-3xl font-bold text-gray-250 pb-3">R&R as a Strategic Differentiator</h2>
                                <p className="mb-6 text-gray-250">
                                    Begin your journey by understanding the transformative power of recognition and rewards. Learn how to align your R&R strategy with broader business goals and unlock the potential of employee engagement. Uncover the science of motivation and engagement, revealing how recognition programs can significantly impact employee behavior. By the end of this masterclass, you'll have a clear vision for how to make R&R a strategic differentiator for your organization.
                                </p>

                                <div className="md:flex gap-4 items-center">
                                    <button onClick={onOpenModal} className="order-1 md:order-2 mb-4 md:mb-0 px-4 py-2 rounded-full hover:bg-blue-700 transition text-gray-250 border border-gray-250">
                                        Register Now
                                    </button>
                                    <button onClick={() => toggleSection(1)} className="order-2 md:order-1 flex px-4 py-2 rounded-full hover:bg-gray-300 transition text-gray-250 border border-gray-250">
                                        <span>Lesson details</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#29294C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path d="M6 9l6 6 6-6" />
                                            <path d="M6 13l6 6 6-6" />
                                        </svg>
                                    </button>
                                </div>



                                <div ref={(el) => (contentRef.current[1] = el)}
                                    className={`transition-max-height ${openSectionId === 1 ? 'expanded' : ''}`}
                                    style={{ maxHeight: openSectionId === 1 ? `${contentRef.current[1]?.scrollHeight}px` : '0px' }}>
                                    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">R&R as a Business Imperative</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Understand the critical role of R&R in driving business success.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">The Science of Recognition</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Explore the psychology behind recognition and its impact on employee behavior.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Reimagining R&R</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Discover the key shifts needed to create high-impact R&R programs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Second Masterclass */}
                    <div className="rounded-lg bg-purple-30 p-6 md:pr-6 pb-6 mb-12">
                        <div className="md:flex flex-col lg:flex-row items-start gap-9">
                            <div className="w-full lg:w-1/5">
                                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1724674279/gatsbycms/uploads/2024/08/Image-2_1.png" alt="Girl with laptop" className="rounded-lg shadow-md w-full h-auto" />
                            </div>
                            <div className="w-full lg:w-4/5 flex flex-col justify-start">
                                <h2 className="pt-6 text-2xl lg:text-3xl font-bold text-gray-250 pb-3">Crafting Impactful R&R Programs</h2>
                                <p className="mb-6 text-gray-250">
                                    Transform your vision into reality with practical guidance on program design and implementation. Learn from real-world examples of successful R&R programs and gain insights into creating a launch strategy that drives adoption. You'll leave this masterclass equipped with the tools and knowledge to build a compelling R&R program tailored to your organization's unique needs.
                                </p>

                                <div className="md:flex gap-4 items-center">
                                    <button onClick={onOpenModal} className="order-1 md:order-2 mb-4 md:mb-0 px-4 py-2 rounded-full hover:bg-blue-700 transition text-gray-250 border border-gray-250">
                                        Register Now
                                    </button>
                                    <button onClick={() => toggleSection(2)} className="order-2 md:order-1 flex px-4 py-2 rounded-full hover:bg-gray-300 transition text-gray-250 border border-gray-250">
                                        <span>Lesson details</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#29294C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path d="M6 9l6 6 6-6" />
                                            <path d="M6 13l6 6 6-6" />
                                        </svg>
                                    </button>
                                </div>

                                <div ref={(el) => (contentRef.current[1] = el)}
                                    className={`transition-max-height ${openSectionId === 2 ? 'expanded' : ''}`}
                                    style={{ maxHeight: openSectionId === 2 ? `${contentRef.current[1]?.scrollHeight}px` : '0px' }}>
                                    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Design for Impact</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Learn the essential elements of a successful R&R program.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Real-world Success Stories</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Discover how other organizations have designed effective R&R programs.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Launching Right and Driving Adoption</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Hone into key learnings from an actual program launch.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Third Masterclass */}
                    <div className="rounded-lg bg-purple-30 p-6 md:pr-6 pb-6 mb-12">
                        <div className="md:flex flex-col lg:flex-row items-start gap-9">
                            <div className="w-full lg:w-1/5">
                                <img src="https://res.cloudinary.com/vantagecircle/image/upload/v1724674280/gatsbycms/uploads/2024/08/Image-3_2.png" alt="Group of people" className="rounded-lg shadow-md w-full h-auto" />
                            </div>
                            <div className="w-full lg:w-4/5 flex flex-col justify-start">
                                <h2 className="pt-6 text-2xl lg:text-3xl font-bold text-gray-250 pb-3">Sustaining R&R Program Success</h2>
                                <p className="mb-6 text-gray-250">
                                    Take your R&R program to new heights by mastering the art of execution and measurement. Address common challenges, optimize program performance, and demonstrate the tangible ROI of your investment. Discover how to sustain momentum and create a culture of recognition that drives long-term business success. By the end of this masterclass, you'll have a comprehensive toolkit for maximizing the impact of your R&R initiatives.
                                </p>

                                <div className="md:flex gap-4 items-center">
                                    <button onClick={onOpenModal} className="order-1 md:order-2 mb-4 md:mb-0 px-4 py-2 rounded-full hover:bg-blue-700 transition text-gray-250 border border-gray-250">
                                        Register Now
                                    </button>
                                    <button onClick={() => toggleSection(3)} className="order-2 md:order-1 flex px-4 py-2 rounded-full hover:bg-gray-300 transition text-gray-250 border border-gray-250">
                                        <span>Lesson details</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#29294C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                                            <path d="M6 9l6 6 6-6" />
                                            <path d="M6 13l6 6 6-6" />
                                        </svg>
                                    </button>
                                </div>

                                <div ref={(el) => (contentRef.current[1] = el)}
                                    className={`transition-max-height ${openSectionId === 3 ? 'expanded' : ''}`}
                                    style={{ maxHeight: openSectionId === 3 ? `${contentRef.current[1]?.scrollHeight}px` : '0px' }}>
                                    <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Overcoming R&R Challenges</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Identify and address common obstacles to R&R program success.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Measuring and Improving Performance</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Learn how to analyse execution metrics and enhance the effectiveness of your R&R program.</p>
                                        </div>
                                        <div className="p-6 bg-gray-100 rounded-lg shadow">
                                            <h3 className="text-lg font-semibold text-gray-250 mb-2">Demonstrating ROI</h3>
                                            <p className="text-gray-250 text-opacity-60 mb-0">Understand how to demonstrate the business value of your R&R program.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Modal open={open} onClose={onCloseModal} center>
                <div className="form-section">
                    <h2 className="form-title mt-2 mb-10 text-2xl font-semibold text-gray-900 sm:text-3xl pb-2">Fill up to register now</h2>
                    <HubspotForm
                        portalId='6153052'
                        formId='3b9628df-7260-4e07-8f08-c59e7d6e3335'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                    />
                    {/* <div className="notetext text-center font-normal text-sm">We safeguard your personal information in accordance with our <a href="/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></div> */}
                </div>
            </Modal>
        </Layout>
    );
};

export default MasterClass;
